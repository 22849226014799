function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#destaques-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
            margin:4,
        },
        920:{
            items:3,
            margin:8,
        },
        1170:{
            items:4,
            margin:10,
        }
    }
})

joazin('#noticias-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:2,
            margin:4,
        },
        920:{
            items:3,
            margin:8,
        },
        1170:{
            items:3,
            margin:10,
        }
    }
})

joazin('#parceiros-carousel',{
    loop:true,
    autoplay:true,
    autoplayTimeout:4000,
    nav:false,
    dotsEach:2,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:3,
            margin:4,
        },
        920:{
            items:4,
            margin:7,
        },
        1170:{
            items:6,
            margin:10,
        }
    }
})