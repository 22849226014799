(function(){
	var animateOnScroll = function(){
		$('[data-animate]').each(function(index, el) {
			var pos = el.getBoundingClientRect().top;

			if(pos <= window.innerHeight -100){
				$(this).addClass($(this).data('animate'))
			}
		});
	};
	var jaAnimou = false;

	animateOnScroll();

	$(window).scroll(function(){
		if(jaAnimou) return;

		setTimeout(function(){
			jaAnimou = false;
		},1003);

		animateOnScroll();
	});
})()